<!--
 * @Author: Leeon 282276848@qq.com
 * @Date: 2024-01-15 10:04:39
 * @LastEditors: Leeon 282276848@qq.com
 * @LastEditTime: 2024-03-19 11:04:39
 * @FilePath: \jirh-h5\src\views\convenientEntrance.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="container u-text-center">
    <img :src="imgUrl" />
    <h4>{{ productName }}</h4>
    <p>您选择的借款产品</p>
    <span>正在为您跳转…</span>
  </div>
</template>
<script>
import * as API_Common from "@/api/common.js";
import Storage from "@/utils/storage";

export default {
  name: "ConvenientEntrance",
  data() {
    return {
      sourceType: Storage.getItem("sourceType"),
      imgUrl: Storage.getItem("imgUrl"),
      productName: Storage.getItem("productName"),
    };
  },
  created() {
    this.goProductInfoPage();
  },
  methods: {
    // 跳转产品详情
    goProductInfoPage() {
      // 从不需要登录的产品列表页面跳转过来的
      if (this.sourceType === "1") {
        API_Common.notLoginBuried({
          productId: Storage.getItem("productId"),
          showPage: 17,
          busType: 3,
        }).then((res) => {
          if (200 === res.code) {
            setTimeout(() => {
              window.location.href = Storage.getItem("linkUrl");
            }, 500);
          }
        });
      } else {
        // 从需要登录的产品列表页面跳转过来的
        API_Common.buried({
          productId: Storage.getItem("productId"),
          showPage: 17,
        }).then((res) => {
          if (200 === res.code) {
            setTimeout(() => {
              window.location.href = Storage.getItem("linkUrl");
            }, 500);
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100vh;
  padding-top: 30%;
  background: url("../assets/images/convenient_entrance_bg.png") no-repeat
    center;
  background-size: 100%;
  font-size: 0.32rem;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 600;
  color: #111c31;
  line-height: 0.44rem;

  > img {
    width: 1.2rem;
    height: 1.2rem;
  }

  > h4 {
    margin: 0.12rem 0 0.6rem;
    font-size: 0.32rem;
  }

  > p {
    font-size: 0.28rem;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    color: #111c31;
  }

  > span {
    font-size: 0.28rem;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #868e9e;
  }
}
</style>
